/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  /*
        Purple: hsl(250, 66%, 75%)
        Blue: hsl(207, 90%, 72%)
        Pink: hsl(356, 66%, 75%)
        Teal: hsl(174, 63%, 62%)
        British-Racing-Green: hsl(154, 100%, 13%)
  */
  --first-hue: 174;
  --sat: 63%;
  --lig: 30%;
  --second-hue: 219;
  --first-color: hsl(var(--first-hue), var(--sat), var(--lig));
  --first-color-alt: hsl(var(--first-hue), var(--sat), 26%); /* -4% */
  --title-color: hsl(var(--second-hue), 15%, 95%);
  --text-color: hsl(var(--second-hue), 8%, 90%);
  --text-color-light: hsl(var(--second-hue), 4%, 75%);
  --body-color: hsl(var(--second-hue), 48%, 8%);
  --container-color: hsl(var(--second-hue), 32%, 12%);

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: 'Abel', sans-serif;
  --biggest-font-size: 2rem;
  --h1-font-size: 1.75rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1.2rem;
  --small-font-size: 1.1rem;
  --smaller-font-size: 1rem;
  --tiny-font-size: 1.15rem;

  /*========== Font weight ==========*/
  --font-medium: 700;
  --font-semibold: 800;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Responsive typography */
@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 2.8rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1.4rem;
    --normal-font-size: 1.25rem;
    --small-font-size: 1.1rem;
    --smaller-font-size: .813rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
pre,
textarea {
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size);
  letter-spacing: .5px;
}



body {
  background-color: var(--body-color);
  color: var(--text-color);
  transition: .4s; 
  line-height: 1.33;
}

h1, h2, h3 {
  color: var(--title-color);
  font-weight: var(--font-semibold);
}

ul {
  list-style: none;
  padding-left: 0;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* Lazy Load */

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

.scroll_header {
  box-shadow: 0 4px 4px hsla(0, 0%, 4%, 0.3);
  background-color: var(--body-color);
}




/*=============== REUSABLE CSS CLASSES ===============*/
.container {
  max-width: 968px;
}

/* #logo{
  height: 50%;
} */

.grid {
  display: grid;
  gap: 1.25rem;
}

.section {
  padding: 4.5rem 0 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.section__title{
  text-align: center;
  text-shadow: 1.5px 1.5px 3px #506d4a;
  font-weight: bold;
  word-spacing: .5rem;
  letter-spacing: .2rem;
}

.section__title {
  font-size: 2.2rem;
  color: var(--text-color);
  margin-bottom: 2rem;
  text-transform: uppercase;
}

/*=============== BUTTONS ===============*/

.button{
  display: inline-block;
  background-color: var(--first-color-alt);
  color: #fff;
  padding: 1rem;
  border-radius: .5rem;
  font-weight: var(--font-medium);
}

.button:hover{
  background-color: var(--first-color);
  color: #fff;
}

.button:hover
.button__icon
{
  transform: translateX(.3rem)
}

.button__icon{
  font-size: 1.25rem;
  margin-left: .3rem;
  transition: .3s;
}

.button__flex{
  display: inline-flex;
  align-items: center;
}

.hidden__social__name{
  display: none;
}

.show_scroll {
  bottom: 2.5rem;
}

.swiper-slide {
  border-radius: 20px;
}

/*=============== SCROLL BAR ===============*/

::-webkit-scrollbar{
  width: .5rem;
  border-radius: .5rem;
  background-color: hsl(var(--second-hue), 8%, 38%);
}

::-webkit-scrollbar-thumb{
  background-color: hsl(var(--second-hue), 8%, 26%);
  border-radius: .5rem;
}
::-webkit-scrollbar-thumb:hover{
  background-color: hsl(var(--second-hue), 8%, 20%);
}


/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width : 350px) {

  .container{
    margin:0 1rem;
  }

}

@media screen and (max-width : 500px) {

  .container{
  }

}

/* For medium devices */
@media screen and (min-width: 568px){
  .container {
  max-width: 968px;
  margin-right: 2rem;
}
}

@media screen and (min-width: 768px) {

}

/* For large devices */

@media screen and (min-width: 1024px) {

  .container{
    margin-left: auto;
    margin-right: auto;
  }

  .section__title{
    margin-bottom: 3rem;
  }

}


