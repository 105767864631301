/*=============== SCROLL UP ===============*/

.scrollup {
    position: fixed;
    right: 1rem;
    bottom: -20%;
    background-color: var(--first-color);
    opacity: .8;
    padding: .4rem .3rem .1rem;
    border-radius: .4rem;
    z-index: var(--z-tooltip);
    transition: .4s;
}

.scrollup:hover {
    background-color: var(--first-color-alt);
}

.show_scroll {
    bottom: 4rem;
}

.scrollup__icon {
    font-size: 2rem;
    color: #FFF;
  }

@media screen and (max-width: 500px){
    
}