/*=============== HEADER & NAV===============*/
.header {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: var(--z-fixed);
  transition: 0.4s;
}

.active__expanded__nav_button {
  color: var(--first-color) !important;
}

.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1198px;
}

.nav__logo,
.nav__toggle {
  font-size: var(--h2-font-size);
  color: #fff;
  font-weight: var(--font-semibold);
  transition: 0.4s;
}

.nav__logo:hover {
  color: var(--first-color-alt);
}

.nav__toggle:hover {
  color: var(--first-color-alt);
  cursor: pointer;
}

.navLogo{
  width: 6.5rem;
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    background-color: var(--body-color);
    width: 100% !important;
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    backdrop-filter: blur(10px);
    transition: 0.4s;
  }

  .header{
    background-color: var(--body-color);
  }

  .show_button{
    display: block !important;
  }

  .nav__toggle{
    display: inline !important;
  }
}

.nav__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--normal-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: 0.4s;
  font-size: 1.31rem;
  padding-top: 1rem;
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__icon {
  font-size: 1.2rem;
}

.nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: 1.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--title-color);
}

.nav__close:hover {
  color: var(--first-color-alt);
}

/* Show Menu */

.show_menu {
  bottom: 0;
}

/* Active link */

.active_link {
  color: var(--first-color) !important;
}

/* Change background header */


.active__expanded__nav_button {
  color: var(--first-color) !important;
}

@media screen and (max-width: 350px) {
  .nav__menu {
    padding: 1rem 1.5rem;
  }
}

@media screen and (min-width: 568px) {
  .nav__menu {
    width: 328px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .navLogo{
    width: 9rem;
  }
}

@media screen and (min-width: 768px) {
  .header {
    top: 0;
    bottom: initial;
  }

  .header {
    padding: 0 1rem;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
    column-gap: 1rem;
  }

  .nav__item {
    cursor: pointer;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: none;
  }

  .nav__list {
    display: flex;
    column-gap: 2rem;
    justify-content: flex-end;
  }

  .nav__menu {
    margin-left: auto;
    width: 700px;
  }
}

@media screen and (max-width : 1212px) {
  .nav__menu{
    width: 70%;
  }
}

@media screen and (max-width : 500px) {
  #logo {
    width: 3.7rem;
  }

  .header{
    box-shadow: 0 4px 4px hsla(0, 0%, 4%, 0.3);
    background-color: var(--body-color);
    border-radius: .6rem .6rem 0 0;
  }

  .nav__icon{
    display: block;
}
.nav__link{
    text-align: center;
}

}


/* For large devices */

@media screen and (min-width: 1024px) {
  .nav {
    height: calc(var(--header-height) + 1rem);
  }
}

@media screen and (min-width: 1212px) {
  .nav__menu {
    margin-left: auto;
    width: 700px;
    width: 90%;
  }
}
