/*=============== CONTACT ===============*/

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-color);
  transition: background-color 5000s ease-in-out 0s;
}

.contact{
  padding-top: 3rem !important;
}

.contact__container{
  row-gap: 3rem;
  padding-bottom: 3rem;
}

.contact__title{
  text-align: center;
  font-size: var(--h3-font-size);
  margin-bottom: 1.5rem;
}

.contact__info{
  display: grid;
  gap: 1rem;
}

.contact__card{
  background-color: var(--container-color);
  padding: 1rem;
  border-radius: .75rem;
  text-align: center;
}

.contact__card_icon{
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: .25rem;
}

.contact__write_us{
  color: var(--first-color);
}

.contact__card_title,
.contact__card_data{
  font-size: var(--small-font-size);
}

.contact__card_title{
  font-weight: var(--font-medium);
  margin-bottom: .1rem;
} 

.contact__card_data{
  display: block;
  margin-bottom: .1rem;
}

.contact__button{
  color: var(--first-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: .25rem;
}

.contact__button:hover .contact__button_icon{
  transform: translateX(.25rem);
}

.contact__button_icon{
  font-size: 1rem;
  transition: .4s;
}

.contact__write_us{
  font-weight: bold;
  font-size: 1.2rem;
}

.contact__form_div{
  position: relative;
  margin-bottom: 2rem;
  height: 4rem;
}

.contact__form_input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid var(--text-color-light);
  background: none;
  color: var(--text-color);
  outline: none;
  padding: 1.5rem;
  border-radius: .75rem;
  z-index: 10;
}

.contact__form_tag{
  position: absolute;
  top: -.75rem;
  left: 1.25rem;
  font-size: var(--small-font-size);
  padding: .25rem;
  background-color: var(--body-color);
  z-index: 20;
}

.contact__form_area{
  height: 11rem;
}

.contact__form_area textarea{
  resize: none;
}

.contact__form_error{
  position: absolute;
  top: 3rem;
  left: 1rem;
  font-size: 1.03rem;
  padding: .25rem;
  background-color: var(--body-color);
  z-index: 20;
  color: #DA2222;
}

.contact__form_error_textarea{
  top:10rem
}

.contacts__title{
  margin-top:2.7rem;
}

@media screen and (max-width: 500px){
  .contact{
    
    padding-top: 0 !important;
  }

  .contacts__title{
    
  }

  .contact__container{
    padding-right: 1rem;
  }

  .contact__form{
    padding-top: 1rem;
  }
}

@media screen and (min-width: 568px){
  .contact__info{
    grid-template-columns: 300px;
    justify-content: center;
  }

  .contact__form{
    width: 360px;
    margin: 0 auto;
    margin-top: 1.2rem;
  }
}

@media screen and (min-width: 768px) {
  .contact__container{
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 3rem;
  }
}

@media screen and (min-width: 1024px) {
  .contact__container{
    column-gap: 6rem;
  }
}