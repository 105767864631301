.gallery{
    text-align: center;
}

.gallery__section{
text-align: center;
}

.gallery__content{
text-align: center;
}

.gallery__container{
    text-align: center;
}

.gallery__columns{
    columns: 3 20rem;
    column-gap: 1.5rem;
}

.image{
    width: 100%
}

@media screen and (max-width: 500px){
    /* .image{
        width: 50%;
    } */

    .gallery__columns{
        columns: 2 5rem;
        column-gap: 1rem;
        padding: 0 1rem;
    }
    
}

@media screen and (min-width: 768px) {
    .gallery__columns{    
        padding: 0 5rem 
    }
}

