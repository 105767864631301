.services__content {
  width: 100%;
  margin-top: 3rem;
}

.services__container {
  grid-template-columns: repeat(2, 1fr);
  padding-top: 1rem;
}

.service__image{
    width: 100%;
    object-fit: cover;
    border-radius: 1rem;
    transition: .4s;
  }

  
  .service__image:hover{
    object-fit: contain;
    transform: scale3d(0.9, 0.85, 0.9);
  }

.services__card {
  background-color: var(--container-color);
  padding: 3rem 1.5rem;
  border-radius: 1rem;
  text-align: center;
}

.services__title {
  font-size: var(--h3-font-size);
  margin-top: 2rem;
}

.services__detail {
  padding: 1rem;
  font-size: var(--normal-font-size);
  color: var(--text-color-light);
  font-weight: 300;
}

.services__button {
  color: var(--first-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: .25rem;
  cursor: pointer;
}

.services__button:hover .services__icon {
  transform: translateX(.25rem);
}

.services__icon {
  font-size: 1rem;
  transition: .4s;
}

/* Services modal */

.modal-content {
  inset: 0;
  background-color: hsla(var(--second-hue), 28%, 16%, 1);
  padding: 1.5rem 1rem;
  display: grid;
  /* place-items: center;
    visibility: hidden;
    opacity: 0; */
  transition: .4s;
  z-index: var(--z-modal);
}

.services__modal-content {
  position: relative;
  background-color: var(--body-color);
  padding: 4.5rem 1.5rem 2.5rem;
  border-radius: 1.5rem;
}

.services__modal-title,
.services__modal-description {
  text-align: center;
}

.services__modal-title {
  font-size: var(--h2-font-size);
}

.modal-header {
  border-bottom: none;
}

.modal-header .btn-close {
  position: relative;
  bottom: 1.3rem;
}


.services__modal-description {
  font-size: var(--small-font-size);
  margin-bottom: 2rem;
}

.services__modal-list {
  display: grid;
  row-gap: .75rem;
}

.services__modal-item {
  display: flex;
  align-items: flex-start;
  column-gap: .5rem;
  padding-left: .1rem;
}

.services__modal-icon {
  font-size: 1.5rem;
  color: var(--first-color);
}

.services__modal-info {
  font-size: var(--small-font-size);
  text-align: left;
}

.services__modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

@media screen and (max-width : 350px) {
  .services__container{
    grid-template-columns: 145px;
    justify-content: center;
  }
}

@media screen and (min-width: 568px){
  .services__container{
    padding: 3rem;
    grid-template-columns: repeat(2, 50%);
    justify-content: center;
    gap: 2.5rem;
  }

  .services__modal-content{
    width: 500px;
    padding: 4.5rem 2.5rem 2.5rem;
  }

  .services__modal-description{
    padding: 0 1rem;
  }
}

@media screen and (max-width : 500px) {

  .services__container{
    grid-template-columns: repeat(1, 1fr);
  }

  .services{
    padding-top: 2rem;
  }

  .modal-content{
    padding: .5rem .25rem;
  }

  .services__card{
    padding: 1rem .75rem;
  }

  .services__title{
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 768px) {

}

@media screen and (min-width: 1024px) {
  .services__container{
    max-width: 85%;
    grid-template-columns: repeat(3, 33%);
    column-gap: 3rem;
  }

  .services__card{
    padding: 0;
    width: 100%;
  }

}

