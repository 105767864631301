.home {
  margin-top: 0;
}

.home__title__results{
  color: #bc9c22;
}

.home__title{
  font-size: 3.5rem;
  letter-spacing: .2rem;
  font-weight: bold;
}

.home__container {
  gap: 1rem;
  padding-bottom: 7rem;
}

.home__content {
  grid-template-columns: .5fr 3fr;
  padding-top: .5rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__social_icon {
  width: max-content;
  background-color: var(--container-color);
  color: var(--first-color);
  font-size: var(--h2-font-size);
  padding: .25rem;
  border-radius: .25rem;
  display: flex;
  transition: .4s;
}

.home__social_icon:hover {
  color: #fff;
  background-color: var(--first-color);
}

.home__data {
  grid-column: 1/3;
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}

.home__title {
  background-color: rgba(255, 255, 255, 0.005);
  width: fit-content;
}

.home__subtitle {
  font-size: 1.8rem;
  color: var(--text-color);
  font-weight: var(--font-medium);
  margin-bottom: .75rem;
  text-shadow: .5px .5px 1px #506d4a;
}

.home__animated__text {
  color: var(--first-color);
}

.home__description {
  margin-bottom: .5rem;
  width: 80%;
}

.home__scroll {
  display: none;
}

.home__scroll_button {
  color: var(--first-color);
  transition: .3s;
}

.home__scroll_button:hover {
  transform: translateY(.25rem);
}

.home__scroll_calender {
  font-size: var(--h1-font-size);
  color: var(--first-color);
}

.home__scroll_name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  color: var(--title-color);
  margin-right: .25rem;
}

.home__scroll_arrow {
  font-size: var(--h3-font-size);
  color: var(--first-color);
}

.button:hover
.home__button__icon{
  transition: .2s;
  transform: translateY(.3rem);
}

.home__button__icon{
  margin-left: .7rem;
  display: flex;
  justify-content: center;
}

.videoBg {
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: -1;
  width: 100%;
  filter: blur(2px);
  opacity: 30%;
}

.home__button__phone__icon{
  padding: 0 .5rem;
}

.home__bottom__buttons{
  display: flex; 
  gap: 1rem;
}

@media screen and (max-width : 350px) {
  .home__content{
    grid-template-columns: .25fr 3fr;
  }
}

@media screen and (max-width : 400px) {
 .home__content{
    padding-top: 5rem;
  }
}


@media screen and (max-width : 568px) {
  .home{
    padding-top: 2.5rem;
  }

  .home__content{
    grid-template-columns: .25fr 3fr;
  }

  .home__description {
    width: 100%;
  }

  .animated__text__container{
    margin-top: 1rem;
    height: 4.2rem;
  }

  .home__title__container{
    display: flex;
    justify-content: center;
  }

  .home__social{
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    gap: .1rem;
    margin-bottom: 2rem;
  }

  .home__data{
    text-align: center;
    align-content: center;
    justify-content: center;
  }
  
  .home__scroll{
    display: none;
  }

  .videoBg{
    
  }

  .home__bottom__buttons{
    flex-direction: column;
  }
}

@media screen and (min-width: 568px){
  .home__content{
    display: flex;
    grid-template-columns: max-content 3.6fr 1fr;
  }
  .home__data{
    grid-column: initial;
  }

  .home__social{
    display: flex;
    gap: 1rem;
  }

  .home__social{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .home__container{
    row-gap: 2rem;
  }

  .home__content{
    padding-top: .5rem;
    column-gap: 2rem;
  }

  .home__scroll{
    display: block;
  }

  .home__scroll_button{
    margin-left: 3rem;
  }

  
}

@media screen and (min-width: 1024px) {
  .home{
    padding: 6.5rem 0 1rem;
  }

  .home__social{
    margin-right: 2rem;
  }
  
}